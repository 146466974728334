import React from "react"
import DashboardStandoutBox from "../components/DashboardStandoutBox"

export default props => {
  return (
    <>
      <DashboardStandoutBox
        icon="fa-list"
        count="3"
        caption="Sessional Records To Complete"
      />
      <DashboardStandoutBox
        icon="fa-business-time"
        count="2"
        caption="Active In Projects This Month"
      />
      {/* <DashboardStandoutBox icon="fa-user-clock" count="54%" caption="Client Attendance Last Week" /> */}
      <DashboardStandoutBox
        icon="fa-chart-pie"
        count="2"
        caption="Sessions Scheduled for Week"
      />
    </>
  )
}
