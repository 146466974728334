import React from "react"
import SessionOutline from "../components/SessionOutline"
import SessionsScheduledForWeek from "../components/SessionsScheduledForWeek"
export default (props) => {
  // const attendee1 = { name: "Aziz Antelope" }
  // const attendee2 = { name: "Brian Bear" }
  // const session1 = { name: "Percussive Jam", date: "11-Jul-2019", attendees: [attendee1, attendee2] }
  // const session2 = { name: "Drumming Jam", date: "18-Jul-2019", attendees: [attendee1] }
  return <>

    <div className="col-lg-6 mb-4">
      <div className="card shadow mb-4">
        <SessionsScheduledForWeek weekStarting="29-Jul-2019" />


        <div className="card-body">
          {/* Duplicated in ProjectSessionsForWeek and SessionsScheduledForWeek.js */}
          <div className="card-body">
            <SessionOutline icon="edit" sessionDate="Fri 2-Aug-2019 1000-1130" location="Rehearsal Room"
              caption="Drumming Jam" practitionerName="Pedro Perez"
              placesBooked="2" capacity="7" />
            <SessionOutline icon="edit" sessionDate="Fri 2-Aug-2019 1400-1530" location="Rehearsal Room"
              caption="Drumming Jam" practitionerName="Pedro Perez"
              placesBooked="2" capacity="7" />

          </div>


        </div>
      </div>

    </div>
  </>
}

