import React, { useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"
import DashboardProjectAttendanceBoxTitle from "../components/DashboardProjectAttendanceBoxTitle"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

import AxiosService from "../services/AxiosService"
import LogService from "../services/LogService"
import PageContext from "../context/PageContext"
import UserContext from "../context/UserContext"
import RefDataLookup from "../components/RefDataLookup"
import AddEntity from "../components/icons/AddEntity"
import Refresh from "./icons/Refresh"

export default (props) => {
  let thisUserContext = useContext(UserContext)

  const success = data => {
    thisUserContext.setClients(data.data)
  }
  const fail = err => {
    LogService.logHttpResponse(err)
  }

  const getClients = token => {
    new AxiosService().getClients(token, success, fail)
  }
 

  const getClientsFromUserContext = user => {
    if (user.Clients) {
      return user.Clients
    }
    return []
  }

  useEffect(() => {
    // Should not ever set state during rendering, so do this in useEffect instead.
    if (props.action==="refresh"){
      getClients(thisUserContext.Token)
    }
    else{
      getClients(thisUserContext.Token)
    }
  }, [])
  
  var pageContext = useContext(PageContext)
  pageContext.setPageName(`Administer Clients`)

  return (
    <>
      <UserContext.Consumer>
        {user => (
          <div className="col-lg-12 mb-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <DashboardProjectAttendanceBoxTitle
                  caption={`Clients (${
                    new RefDataLookup().getClientsFromUserContext(user).length
                  })`}
                />
                <div style={{float:"right", marginTop:"-18px", marginRight:"0px"}}>

                <Refresh  tooltiptext="Refresh"
                  onClick={event => {
                    getClients(thisUserContext.Token)
                  }}
                />
                
                <div style={{float:"left", marginTop:"0px", marginRight:"10px"}}>
                  <AddEntity
                  tooltiptext="Create Client"
                  onClick={event => {
                    event.preventDefault()
                    navigate(`/app/administer-clients/create`)
                  }}
                /></div>
                </div>
              </div>

              <div className="card-body">
                {new RefDataLookup().getClientsFromUserContext(user).length >
                0 ? (
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Forename</th>
                        <th>Surname</th>
                        <th>View</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getClientsFromUserContext(user).map((elem, index) => {
                        return (
                          <tr key={index}>
                            <td>{elem.forename} </td>
                            <td>{elem.surname}</td>
                            <td>
                              <Link to={`/app/administer-client/${elem.id}`}>
                                <FontAwesomeIcon icon="eye" />
                              </Link>
                            </td>
                            <td>
                              <Link to={`/app/administer-client/${elem.id}/edit`}>
                                <FontAwesomeIcon icon="pencil-alt" />
                              </Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p>none</p>
                )}
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </>
  )
}
