import React from "react"
import ClientSelect from "../components/ClientSelect"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

export default props => {
  const filteredClients=()=>{
    if (props.filterOn){
      let filteredList=props.clients.filter(c=>props.filterOn.indexOf(c.id)>=0)
      return  filteredList
    }
    return props.clients
  }


  return (
    <>
      <p>Clients</p>
      <div
        className="row"
        style={{
          width: "100%",
          border: "1px solid #d1d3e2",
          marginBottom: "16px",
          marginLeft: "0px",
          marginRight: "10px",
          borderRadius: "0.35rem",
        }}
      >
        <table
          style={{
            width: "100%",
            marginBottom: "16px",
            marginLeft: "10px",
            marginRight: "10px",
            borderRadius: "30.35rem",
          }}
        >
          <thead>
            <tr>
              <th>Forename</th>
              <th>Surname</th>
              {props.invite ? (
                <th>Assign<FontAwesomeIcon
                icon="info-circle"
                tooltiptext="Only clients enrolled in a project can be invited to sessions. You can enroll clients in the Administer Project page"
              /></th>
              ) : (
                <th>
                  Enrolled
                  <FontAwesomeIcon
                    icon="info-circle"
                    tooltiptext="Only enrolled clients can be invited to sessions"
                  />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            
              {filteredClients().map((elem, index) => {
              return (
                <ClientSelect
                  key={elem.id}
                  forename={elem.forename}
                  surname={elem.surname}
                  id={elem.id}
                  selected={elem.selected}
                  onChange={event => props.onChange(event, props.returnId? elem.id: index)}
                />
              )
            })}
          </tbody>
        </table>
        {props.timestamp ? <p>Last updated: {props.timestamp}</p> : <p></p>}
      </div>
    </>
  )
}
