import React, { useContext } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"

import FullWidthContainer from "../components/FullWidthContainer"
import AxiosService from "../services/AxiosService"
import LogService from "../services/LogService"
import PageContext from "../context/PageContext"
import UserContext from "../context/UserContext"
import FormInputControlled from "./FormInputControlled"

const AdministerClientCreate = () => {
  var userContext = useContext(UserContext)
  var pageContext = useContext(PageContext)
  pageContext.setPageName(`Create Client`)

  const { register, handleSubmit, errors } = useForm()
  const onSubmit = data => {
    var output = {
      OrgKey: userContext.user.orgKey,
      ClientRequestRef: new Date().getTime(),
      forename: data.forename,
      surname: data.surname,
    }
    var body = JSON.stringify(output)
    new AxiosService().createClient(
      userContext.Token,
      body,
      successCreateSession,
      failCreateSession
    )
  }

  const successCreateSession = data => {
    let ts = new Date().getTime()
    navigate(`/app/administer-clients/refresh/${ts}`)
  }

  const failCreateSession = err => {
    LogService.logHttpResponse(err)
  }

  const forenameMinLength = 2
  const surnameMinLength = 2
  const forenameMaxLength = 40
  const surnameMaxLength = 40

  return (
    <>
      <PageContext.Consumer>
        {page => (
          <>
            <FullWidthContainer caption={"New Client"}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInputControlled
                  required={true}
                  minLength={forenameMinLength}
                  maxLength={forenameMaxLength}
                  label="Forename"
                  property="forename"
                  register={register}
                  errorProp={errors.forename}
                  defaultValue="?"
                  useValue={false}
                />

                <FormInputControlled
                  required={true}
                  minLength={surnameMinLength}
                  maxLength={surnameMaxLength}
                  label="Surname"
                  property="surname"
                  register={register}
                  errorProp={errors.surname}
                  defaultValue="?"
                  useValue={false}
                />

                <input type="submit" />
              </form>
            </FullWidthContainer>
          </>
        )}
      </PageContext.Consumer>
    </>
  )
}
export default AdministerClientCreate
