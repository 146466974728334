import React from "react"

const TabControl = (props) => {
  return (
    <>
      <ul className="nav nav-tabs">
        {props.tabs.map((elem, index) => 
          <li key={index} className="nav-item" style={{ cursor: "pointer" }}>
            <a
              className={`nav-link ${props.activeTab === elem ? "active" : ""}`}
              onClick={event => props.onClick(elem)}
            >
              {elem} 
            </a>
          </li>
        )}
      </ul>
    </>
  )
}
export default TabControl
