import React from "react"
import { Link } from "gatsby"
import SessionalRecordsToCompleteTitleLine from "../components/SessionalRecordsToCompleteTitleLine"

const SessionalRecordsToCompleteLine =(props) => {
  return <>

  <SessionalRecordsToCompleteTitleLine session={props.session}/>

   
    {props.session.attendees.map((attendee, index) => {
      return (
        <p key={index}>
          <Link to="/sessional-record" state={{ attendee: attendee , session: props.session }}>
            
            <i className={`fas fa-${props.icon}`} style={{ paddingRight: "2px" }}></i>{attendee.name}
          </Link></p>
      )
    })}


  </>
}
export default SessionalRecordsToCompleteLine
