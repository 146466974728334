import React from "react"
import ClientEnrollment from "../components/ClientEnrollment"
import FullWidthContainer from "../components/FullWidthContainer"

const AdministerProjectProperties = props => {
  return (
    <>
      <FullWidthContainer icon={props.icon} caption={`${props.name}`}>
        <p>Name: {props.name}</p>
        <p>Commissioning Body: {props.commissioningBodyName}</p>

        <ClientEnrollment
          clients={props.clients}
          onChange={props.onChange}
          timestamp={props.timestamp}
        />

        <div className="row">
          <div className="col-lg-8 mb-8"></div>
          <div className="col-lg-4 mb-4">
            <div hidden={props.showButtons}>
              <button onClick={props.onSave}>Save</button>
              <button onClick={props.onCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </FullWidthContainer>

      {/* <div className="row">
              <ProjectSessionsForWeek />
              <ProjectEnrollment />
            </div> */}
    </>
  )
}
export default AdministerProjectProperties
