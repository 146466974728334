class RefDataLookup {
  getCommissioningBodyName = (user, id) => {
    if (user.ReferenceData && user.ReferenceData.commissioningBodies) {
      return user.ReferenceData.commissioningBodies.find(a => a.id === id).name
    }
    return "UNKNOWN"
  }

  getClientsFromUserContext = user => {
    if (user.Clients) {
      return user.Clients
    }
    return []
  }
}
export default RefDataLookup
