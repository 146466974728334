import React, { useContext, useState, useEffect } from "react"
import PageContext from "../context/PageContext"
import UserContext from "../context/UserContext"

import AdministerProjectProperties from "../components/AdministerProjectProperties"
import AdministerProjectSessions from "../components/AdministerProjectSessions"
import AxiosService from "../services/AxiosService"
import LogService from "../services/LogService"
import { navigate } from "gatsby"
import TabControl from "./TabControl"

let name
let commissioningBodyName
let timestamp
let token
const AdministerProject = props => {
  const [clients, setClients] = useState([])
  const [tab, setTab] = useState("Project")
  const userWrapper = useContext(UserContext)
  const userproj = userWrapper.ReferenceData.projects.find(
    p => p.id === props.id
  )
  token = userWrapper.Token
  const proj = JSON.parse(JSON.stringify(userproj))
  var pageContext = useContext(PageContext)
  name = proj.name
  let ts = new Date(proj.timestamp)

  let t = ts.toLocaleTimeString("en-GB")
  let d = ts.toLocaleDateString("en-GB")
  timestamp = `${d} ${t}`
  pageContext.setPageName(`Administer ${name}`)
  commissioningBodyName = proj.commissioningBodyName

  const handleCheckedChange = (event, index) => {
    var client = clients[index]
    client.selected = event.target.checked
    setClients([...clients])
  }

  useEffect(() => {
    // Should not ever set state during rendering, so do this in useEffect instead.
    //setStateValues(allowedState);
    setClients(proj.clients)
  }, [])

  const getDirty = () => {
    var dirty = false
    for (let i = 0; i < clients.length; i++) {
      var client = clients[i]
      if (client.selected !== client.originalSelected) {
        dirty = true
        break
      }
    }
    return dirty
  }
  const Save = () => {
    let clientIdString = ""
    clients.forEach(element => {
      if (element.selected) {
        clientIdString += clientIdString.length > 0 ? "," : ""
        clientIdString += element.id
      }
    })
    proj.clientIdString = clientIdString
    new AxiosService().updateProject(token, proj, success, fail)
  }

  const success = data => {
    let ts = new Date().getTime()
    navigate(`/app/administer-projects/refresh/${ts}`) //TODO: message
  }
  const fail = err => {
    LogService.logHttpResponse(err)
  }

  const Cancel = () => {
    setClients(proj.clients)
  }
  const onTabClick=(event)=>{
    setTab(event);
  }
  return (
    <>
      <PageContext.Consumer>
        {page => (
          <>
            <TabControl tabs={["Project","Sessions"]} onClick={onTabClick} activeTab={tab}/>
            <div>
              <div hidden={tab === "Sessions"}>
                <AdministerProjectProperties
                  name={name}
                  icon={props.icon}
                  commissioningBodyName={commissioningBodyName}
                  clients={clients}
                  timestamp={timestamp}
                  showButtons={!getDirty()}
                  onSave={Save}
                  onCancel={Cancel}
                  onChange={handleCheckedChange}
                />
              </div>
              <div hidden={tab === "Project"}>
                <AdministerProjectSessions
                  token={token}
                  id={props.id}
                  hidden={tab === "Project"}
                />
              </div>
            </div>
            {/* <div className="row">
              <ProjectSessionsForWeek />
              <ProjectEnrollment />
            </div> */}
          </>
        )}
      </PageContext.Consumer>
    </>
  )
}
export default AdministerProject
