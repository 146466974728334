import React,  { useState}  from "react"
import axios from "axios";

export default props => {

  const [apiMessage, setApiMessage] = useState("");
  const [rnd, setRnd] = useState(0);
  const [apiTimestamp, setApiTimestamp] = useState(0);
  //axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  //axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
      
  function getUsers (delay=false) {
      setApiMessage("Fetching....");
      let url="http://localhost:8080/rnd";
      if (delay){
          url+="/2";
      }
      axios
          .get(url)
          .then(data => 
              {
                  setRnd(data.data.rand)
                  setApiTimestamp(data.data.ts)
                  //this.setState({ users: data.data.data })
                  //return data;
                  setApiMessage("");
              }
              )
          .catch(err => {
              setApiMessage("Failed....");
              return null;
          });
   };

   function postUsers (delay=false) {
      setApiMessage("Fetching....");
      let url="http://localhost:8080/rndXXXXX";
      if (delay){
          url+="/2";
      }
      axios
          .post(url, { name: 5 }, {headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }})
          .then(data => 
              {
                  console.log(data.data)
                  setRnd(data.data.rand)
                  setApiTimestamp(data.data.ts)
                  //this.setState({ users: data.data.data })
                  //return data;
                  setApiMessage("");
              }
              )
          .catch(err => {
              console.log("err");
              console.log(err);
              setApiMessage("Failed....");
              return null;
          });
   


   };
   function putUsers (delay=false) {
      setApiMessage("Fetching....");
      let url="http://localhost:8080/rndXXXXX";
      if (delay){
          url+="/2";
      }
      axios
          .put(url, { name: 2 }, {headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }})
          .then(data => 
              {
                  console.log(data.data)
                  setRnd(data.data.rand)
                  setApiTimestamp(data.data.ts)
                  //this.setState({ users: data.data.data })
                  //return data;
                  setApiMessage("");
              }
              )
          .catch(err => {
              console.log("err");
              console.log(err);
              setApiMessage("Failed....");
              return null;
          });
     
      // // axios.post(url, {
      // //     firstName: 'Fred',
      // //     lastName: 'Flintstone'
      // //   })
      // //   .then(function (response) {
      // //     console.log(response);
      // //   })
      // //   .catch(function (error) {
      // //     console.log(error);
      // //   });


      // axios({
      //     method: 'put',
      //     url: url,
      //     data: {
      //       name: 2,
      //       lastName: 'Flintstone'
      //     },
      //     headers: {
      //         'Content-Type': 'application/x-www-form-urlencoded',
      //         'Accept': 'application/json, text/plain, */*'
      //       }
      //   });


   };

//Not sure I see the value in this . How is the error handled????
  //  async function getUsersAsync(delay=false) {
  //     setApiMessage("Fetching....");
  //     let url="http://localhost:8080/rnd";
  //     if (delay){
  //         url+="/2";
  //     }
  //     await axios
  //         .get(url)
  //         .then(data => 
  //             {
  //                 console.log(data.data)
  //                 setRnd(data.data.rand)
  //                 setApiTimestamp(data.data.ts)
  //                 //this.setState({ users: data.data.data })
  //                 //return data;
  //                 setApiMessage("");
  //             }
  //             )
  //         .catch(err => {
  //             console.log("err");
  //             console.log(err);
  //             setApiMessage("Failed....");
  //             return null;
  //         });
  //  };


  return (
    <>
      <button onClick={() => getUsers()}>No delay</button>
      <button onClick={() => getUsers(true)}>Delay</button>
      <button onClick={() => postUsers()}>post</button>
      <button onClick={() => putUsers()}>put</button>

      <p>
        {rnd} {apiTimestamp}{" "}
      </p>
      <p>{apiMessage}</p>
    </>
  )
}
