import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Profile from "../components/profile"
import LoginWrapper from "../components/LoginWrapper"
import AdministerProject from "../components/AdministerProject"
import AdministerProjects from "../components/AdministerProjects"
import AdministerClients from "../components/AdministerClients"
import AdministerClientView from "../components/AdministerClientView"
import AdministerLocations from "../components/AdministerLocations"
import AdministerPractitioners from  "../components/AdministerPractitioners"
import AdministerCommissioningBodies from "../components/AdministerCommissioningBodies"
import AdministerCommissioningBody from "../components/AdministerCommissioningBody"

import DevTestBedAxios from "../components/DevTestBedAxios"
import UserHome from "../components/UserHome";
import UserContext from "../context/UserContext"
import AdministerSessionCreate from "../components/AdministerSessionCreate"
import AdministerSessionEdit from "../components/AdministerSessionEdit"
import AdministerClientCreate  from "../components/AdministerClientCreate"
import AdministerClientEdit  from "../components/AdministerClientEdit"
import ReleaseNotes  from "../components/ReleaseNotes"
import Help  from "../components/Help"



const App = () => (
  <UserContext.Consumer>
        {user => (
  <Router>
          
    <PrivateRoute path="/app/profile" component={Profile}/>
    <PrivateRoute path="/app/home" component={UserHome}/>
    {/* <PrivateRoute path="/app/page-3" component={P3} />
    <PrivateRoute path="/app/boo" component={Boo} /> */}
    {/* <PrivateRoute path="/app/manage-project" component={Boo}/> */}
    
    <PrivateRoute path="/app/administer-projects/:action/:timestamp" component={AdministerProjects}/>
    <PrivateRoute path="/app/administer-projects" component={AdministerProjects}/>    
    <PrivateRoute path="/app/administer-project/:id" xx="22" component={AdministerProject}/>

    <PrivateRoute path="/app/administer-clients" component={AdministerClients}/>    
    <PrivateRoute path="/app/administer-clients/:action/:timestamp" component={AdministerClients}/>
    <PrivateRoute path="/app/administer-clients/create" component={AdministerClientCreate}/>    
    <PrivateRoute path="/app/administer-client/:id/edit" component={AdministerClientEdit}/>    
    <PrivateRoute path="/app/administer-client/:id" component={AdministerClientView}/>    
    
    <PrivateRoute path="/app/administer-locations" data={user.ReferenceData} component={AdministerLocations}/>    
    <PrivateRoute path="/app/administer-practitioners" data={user.ReferenceData} component={AdministerPractitioners}/>    
    
    <PrivateRoute path="/app/administer-session/create/:projectId" component={AdministerSessionCreate}/>    
    <PrivateRoute path="/app/administer-session/:id" component={AdministerSessionEdit}/>    
    {/* <PrivateRoute path="/app/administer-session/:id"  render={(props) => <AdministerSession {...props} fromPR="true" />} />     */}
    
    <PrivateRoute path="/app/administer-commissioning-bodies" data={user.ReferenceData} component={AdministerCommissioningBodies}/>    
    <PrivateRoute path="/app/administer-commissioning-body/:id" data={user.ReferenceData} component={AdministerCommissioningBody}/>    

    
    <PrivateRoute path="/app/dev-testbed" component={DevTestBedAxios}/>

    
    <PrivateRoute path="/app/release-notes" component={ReleaseNotes}/>
    <PrivateRoute path="/app/help" component={Help}/>
    
    
    <LoginWrapper path="/app/login" />
    {/* </>
     */}
  </Router>
  )}
  </UserContext.Consumer>
)

export default App
