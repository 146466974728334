import React from "react"
import DashboardStandoutBox from "../components/DashboardStandoutBox"

export default props => {
  return (
    <>
      <DashboardStandoutBox
        icon="fa-business-time"
        count={props.projectsCount}
        caption="Active Projects"
        to="/app/administer-projects"
      />
      <DashboardStandoutBox
        icon="fa-address-card"
        count={props.clientCount}
        caption="Active Clients"
        to="/app/administer-clients"
      />
      {/* <DashboardStandoutBox
        icon="fa-user-clock"
        count="75%"
        caption="Client Attendance Last Week"
      />
      <DashboardStandoutBox
        icon="fa-chart-pie"
        count="62%"
        caption="Practitioner Utilisation This Week"
      /> */}
    </>
  )
}
