import React, { useContext } from "react"
import { Link } from "gatsby"
import PageContext from "../context/PageContext"
import UserContext from "../context/UserContext"
import DashboardProjectAttendanceBoxTitle from "../components/DashboardProjectAttendanceBoxTitle"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

const AdministerCommissioningBody = props => {
  const userWrapper = useContext(UserContext)
  const projectsForCommBody = userWrapper.ReferenceData.projects.filter(
    p => p.commissioningBodyId === props.id
  )
  const commBody = userWrapper.ReferenceData.commissioningBodies.find(
    p => p.id === props.id
  )

  var pageContext = useContext(PageContext)
  pageContext.setPageName(`Administer ${commBody.name}`)

  return (
    <>
      <PageContext.Consumer>
        {page => (
          <>
            <div className="row">
              <div className="col-lg-12 mb-12">
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <DashboardProjectAttendanceBoxTitle
                      icon={props.icon}
                      caption={`${commBody.name}`}
                    />
                  </div>
                  <div className="card-body">
                    <p>Name:{commBody.name}</p>
                    <p>Projects:</p>
                    <table
                      style={{
                        width: "100%",
                        border: "1px solid gray",
                        marginBottom: "16px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectsForCommBody.map((elem, index) => {
                          return (
                            <tr key={index}>
                              <td>N {elem.name}</td>
                              <td>
                                <Link to={`/app/administer-project/${elem.id}`}>
                                  <FontAwesomeIcon icon="pencil-alt" />
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </PageContext.Consumer>
    </>
  )
}
export default AdministerCommissioningBody
