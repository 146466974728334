import React from "react"

const ReleaseNotes = () => {
  const areaNotes = [
    {
      area: "Clients",
      operations: [
        { name: "Add", progress: 100, notes: "" },
        { name: "View", progress: 100, notes: "" },
        {
          name: "Update",
          progress: 100,
          notes:
            "Clients cannot be added to one or more projects in the AdminsterClient; they must be added to each project in the AdminsterProject page. Doing this in AdminsterClient will be in phase 2.",
        },
        { name: "Delete", progress: 0, notes: "" },
      ],
    },
    {
      area: "Commissioning Bodies",
      operations: [
        { name: "Add", progress: 0, notes: "" },
        { name: "View", progress: 100, notes: "" },
        {
          name: "Update",
          progress: 0,
          notes: "",
        },
        { name: "Delete", progress: 0, notes: "" },
      ],
    },
    {
      area: "Locations",
      operations: [
        { name: "Add", progress: 0, notes: "" },
        { name: "View", progress: 80, notes: "As a list, no links to individual pages" },
        {
          name: "Update",
          progress: 0,
          notes: "",
        },
        { name: "Delete", progress: 0, notes: "" },
      ],
    },
    {
      area: "Practitioners",
      operations: [
        { name: "Add", progress: 0, notes: "" },
        { name: "View", progress: 80, notes: "As a list, no links to individual pages" },
        {
          name: "Update",
          progress: 0,
          notes: "",
        },
        { name: "Delete", progress: 0, notes: "" },
      ],
    },
    {
      area: "Projects",
      operations: [
        { name: "Add", progress: 0, notes: "" },
        { name: "View", progress: 100, notes: "" },
        {
          name: "Update",
          progress: 30,
          notes:
            "Can un-enroll and enroll clients in a project but not update commissioning body or project name",
        },
        { name: "Delete", progress: 0, notes: "" },
      ],
    },
    {
      area: "Administrator Dashboard",
      operations: [{ name: "View", progress: 10, notes: "" }],
    },
    {
      area: "Coordinator Dashboard",
      operations: [{ name: "View", progress: 0, notes: "" }],
    },
    {
      area: "Practitioner Inbox",
      operations: [{ name: "View", progress: 0, notes: "" }],
    },
    {
      area: "Reporting",
      operations: [{ name: "View", progress: 0, notes: "" }],
    },
    {
      area: "Sessional Records",
      operations: [
        { name: "Add", progress: 0, notes: "" },
        { name: "View", progress: 0, notes: "" },
        {
          name: "Update",
          progress: 0,
          notes: "",
        },
      ],
    },
    {
      area: "Secure Login",
      operations: [
        {
          name: "Implement",
          progress: 0,
          notes:
            "For this demo insecure login has been implemented as this is test data",
        },
      ],
    },
  ]
  const progressBar = value => {
    return (
      <div className="progress" style={{ backgroundColor: "#aaa" }}>
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow={value}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${value}%` }}
        >
          {value}%<span className="sr-only">{value}% Complete</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <h2>Release Notes</h2>
        <h4>Demo release 1: 6/2/2020</h4>
        <table
          className="table table-hover"
          style={{
            width: "100%",
            marginBottom: "16px",
            marginLeft: "10px",
            marginRight: "10px",
            borderRadius: "30.35rem",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Area</th>
              <th style={{ width: "15%" }}>Operation</th>
              <th style={{ width: "11%" }}>Progress</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {areaNotes.map((elem, index) => {
              return elem.operations.map((op, index) => {
                return (
                  <tr key={index}>
                    <td>{elem.area}</td>
                    <td>{op.name}</td>
                    <td>{progressBar(op.progress)}</td>
                    <td>{op.notes}</td>
                  </tr>
                )
              })
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ReleaseNotes
