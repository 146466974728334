import React, { useContext, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import PageContext from "../context/PageContext"
import UserContext from "../context/UserContext"

import FullWidthContainer from "./FullWidthContainer"
import AxiosService from "../services/AxiosService"
import LogService from "../services/LogService"
import ClientEnrollment from "./ClientEnrollment"
import FormInput from "./FormInput"
import FormSelect from "../components/FormSelect"

import { navigate } from "gatsby"
var _ = require("lodash")

const AdministerSessionEdit = props => {

  const [session, setSession] = useState({})
  const [projectId, setProjectId] = useState()
  var pageContext = useContext(PageContext)
  pageContext.setPageName(`Administer Session: ${session.name}`)
  
  var userContext = useContext(UserContext)
  const [clients, setClients] = useState([])
  const locations = userContext.ReferenceData.locations
  const practitioners = userContext.ReferenceData.practitioners

  const sessionStartMin = 600
  const sessionStartMax = 2300
  const sessionEndMin = 615
  const sessionEndMax = 2345
  const sessionDurationMin = 15
  const projectNameMinLength = 2
  const projectNameMaxLength = 80

  useEffect(() => {
    // Should not ever set state during rendering, so do this in useEffect instead.
    const setProj=(sess)=>{
      let projId= sess.projectId
      setProjectId(projId)
      let proj = userContext.ReferenceData.projects.find(
        p => p.id === projId
      )

      let tempClients=_.cloneDeep(proj.clients.filter(c=>c.selected))
      let clientsInProj = []

      proj.clients.forEach(c => {
        if (c.selected) {
          clientsInProj.push(c.id)
        }
      })

      var sessionClients= sess.clientIdString.substring(1,sess.clientIdString.length-1).split(",")
      tempClients.forEach(client=>{
        if (sessionClients.indexOf(client.id)<0)
        {
          client.selected = client.originalSelected = false
        }
      })
      setClients(tempClients)
    }

    const successGetSession = data => {
      var sess=data.data
      setSession(sess)
      setProj(sess);
    }

    const failGetSession = err => {
      LogService.logHttpResponse(err)
    }

    const getSession=()=>{
      new AxiosService().getSession(
        userContext.Token,
        props.id,
        successGetSession,
        failGetSession
      )
    }
    getSession()
  }, [props.id, userContext.ReferenceData.projects, userContext.Token])

  const successUpdateSession = data => {
    navigate(`/app/administer-project/${projectId}`)   
  }

  const failUpdateSession = err => {
    LogService.logHttpResponse(err)
  }

  //from POC start
  const toIso = (fullDate, time) => {
    var dt = fullDate.substring(fullDate.length - 10, fullDate.length)
    var dtStart = new Date(
      dt.substring(6, 10),
      parseInt(dt.substring(3, 5)) - 1,
      dt.substring(0, 2)
    )
    dtStart.setHours(time.substring(0, 2))
    dtStart.setMinutes(time.substring(2, 4))
    return dtStart.toISOString()
  }

  const { register, handleSubmit, errors} = useForm()

  const onSubmit = data => {
    if (clients.filter(c=>c.selected).length===0)
    {
      alert("Currently when editing a session you must retain at least one client assignment. This restriction will be removed later.")
      return;
    }
    var dtdt = data.startDate
    let dtS = toIso(dtdt, data.startTime)
    let dtE = toIso(dtdt, data.endTime)

    let clientIdString = ""
    clients.forEach(element => {
      if (element.selected) {
        clientIdString += clientIdString.length > 0 ? "," : ""
        clientIdString += element.id
      }
    })

    var output = {
      OrgKey: userContext.user.orgKey,
      ProjectId: projectId,
      ClientRequestRef: new Date().getTime(),
      Name: data.name,
      //Desc: data.description,
      LocationId: data.locationId,
      Start: dtS,
      End: dtE,
      LeadPractitionerId: data.leadPractitionerId,
      ClientIdString: clientIdString,
      eTag: session.eTag,
      id: session.id,
    }
    var body = JSON.stringify(output)
    new AxiosService().updateSession(userContext.Token, body, successUpdateSession, failUpdateSession) 
  }

  const getStartTimes = () => {
    return getTimes(sessionStartMin, sessionStartMax, sessionDurationMin)
  }

  const getEndTimes = () => {
    return getTimes(sessionEndMin, sessionEndMax, sessionDurationMin)
  }
  const getTimes = (start, end, increment) => {
    let out = []
    while (start <= end) {
      out.push(start.toString().padStart(4, "0"))
      start += increment
      if (start % 100 >= 60) {
        start += 40
      }
    }
    return out
  }
  const getFormattedSessionDate=value=>{
    // const options = {
    //   weekday: "long",
    //   year: "numeric",
    //   month: "numeric",
    //   day: "numeric",
    // }
    return value.toLocaleDateString("en-GB")
  }
  const getDates = (start, end, increment) => {
    let out = []
    let today = Date.now()

    while (start <= end) {
      var newDate = new Date(today + start * 24 * 60 * 60 * 1000)
      out.push(getFormattedSessionDate(newDate))
      start += increment
    }
    return out
  }

  const handleCheckedChange = (event, id) => {
    var client = clients.find(c=>c.id===id)
    client.selected = event.target.checked
    setClients([...clients])
  }
  
  function handleChange(evt) {
    const value = evt.target.value
    setSession({
      ...session,
      [evt.target.name]: value,
    })
  }


  return (
    <>
      <PageContext.Consumer>
        {page => (
          <>
            <FullWidthContainer caption={`${session.name}`}>
              {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
              <form onSubmit={handleSubmit(onSubmit)}>
 <FormInput
                  required={true}
                  minLength={projectNameMinLength}
                  maxLength={projectNameMaxLength}
                  label="Session Name"
                  property="name"
                  register={register}
                  errorProp={errors.name}
                  value={session.name || ""}
                  onChange={handleChange}
                  
                />

<>


<FormSelect   required={true} label="Location"
                  property="locationId"
                  register={register}
                  errorProp={errors.locationId}
                  data= {locations}
                  dataLabel={(elem)=> `${elem.name}` }
                  value={session.locationId}
                  onChange={handleChange} />


<FormSelect   required={true} label="Lead Practitioner "
                  property="leadPractitionerId"
                  register={register}
                  errorProp={errors.leadPractitionerId}
                  data= {practitioners}
                  dataLabel={(elem)=> `${elem.forename} ${elem.surname}` }
                  value={session.leadPractitionerId}
                  onChange={handleChange} />

<FormSelect   required={true} label="Date"
                  property="startDate"
                  register={register}
                  errorProp={errors.startDate}
                  data= {getDates(-7, 14, 1)}
                  dataLabel={(elem)=> `${elem}` }
                  value={session.startDate || ""}
                  onChange={handleChange}/>

  <FormSelect   required={true} label="Start Time"
  register={register}
  errorProp={errors.startTime}
  data= {getStartTimes()}
  dataLabel={(elem)=> `${elem}` }
  value={session.startTime}
  property="startTime"  
  onChange={handleChange}/>


<FormSelect   required={true} label="End Time"
                  property="endTime"
                  register={register}
                  errorProp={errors.endTime}
                  data= {getEndTimes()}
                  dataLabel={(elem)=> `${elem}` }
                  validator={value=>session.startTime < value}
                  validatorMessage="The end time must be after the start time" 
                  value={session.endTime}
                  onChange={handleChange} />
    </>

           
                <div className="form-group">
                  <ClientEnrollment
                    clients={clients}
                    id="clientEnrollment"
                    onChange={handleCheckedChange}
                    invite={true}
                    returnId={true}
                    
                  />
                </div>

                <input type="submit" />
              </form>
            </FullWidthContainer>
          </>
        )}
      </PageContext.Consumer>
    </>
  )
}
export default AdministerSessionEdit
