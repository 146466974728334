import React, { useContext } from "react"
import {Link, navigate} from "gatsby"
import DashboardProjectAttendanceBoxTitle from "../components/DashboardProjectAttendanceBoxTitle"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

import AxiosService from "../services/AxiosService"
import LogService from "../services/LogService"
import PageContext from "../context/PageContext"
import UserContext from "../context/UserContext"
import AddEntity from "../components/icons/AddEntity"
import InfoCircle from "./icons/InfoCircle"


export default (props) => {
  let thisUserContext = useContext(UserContext)
  let successfulCall = null
  
  const success = data => {
    successfulCall = true
    thisUserContext.setReferenceData(data.data, props.action==="refresh", props.timestamp)
  }
  const fail = err => {
    successfulCall = false
    LogService.logHttpResponse(err)
  }

  const getReferenceData = token => {
    if (successfulCall === null && (thisUserContext.ReferenceData === null || props.action==="refresh")) {
      new AxiosService().getReferenceData(token, success, fail)
    }
  }

  getReferenceData(thisUserContext.Token)

  const getProjectsFromUserContext = user => {
    if (user.ReferenceData && user.ReferenceData.projects) {
      if (user.ReferenceData && user.ReferenceData.commissioningBodies) {
        return user.ReferenceData.projects
      }
    }
    return []
  }

  const navigateToCreate=(event, id)=>{
    event.preventDefault()
    navigate(`/app/administer-session/create/${id}`)}

  var pageContext = useContext(PageContext)
  pageContext.setPageName(`Administer Projects`)

  const clientCount=(elem)=>{return (elem.clientIdString.substring(1,elem.clientIdString.length-1).split(",").length - 1) + (elem.clientIdString.substring(1,elem.clientIdString.length-1).length>0?1:0)}

  return (
    <>
      <UserContext.Consumer>
        {user => (
          <div className="col-lg-12 mb-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <DashboardProjectAttendanceBoxTitle
                  caption={`Projects (${
                    getProjectsFromUserContext(user).length
                  })`}
                />
              </div>
              <div className="card-body">
                {getProjectsFromUserContext(user).length > 0 ? (
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Commissioning Body</th>
                        <th>No. of Clients </th>
                        <th>Edit Project</th>
                        <th>Create Session</th>
                      </tr>
                    </thead>
                    <tbody>
                    {getProjectsFromUserContext(user).map((elem, index) => {
                      return (
                        <tr key={index}>
                          <td>{elem.name} </td>
                          <td>
                          {elem.commissioningBodyName}
                          </td>
                          <td>
                            {clientCount(elem)}
                          </td>
                          <td>
                          <Link to={`/app/administer-project/${elem.id}`}>
                            <FontAwesomeIcon icon="pencil-alt" />
                            </Link>
                          </td>
                          <td>
                          {clientCount(elem)===0?(
                            <InfoCircle tooltiptext="You must assign users to a project before you can create a project session"/>
                            
                          ):(
                            <AddEntity tooltiptext="Create Session" onClick={event => {navigateToCreate(event, elem.id)}}/>
                          )}
                            
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                ) : (
                  <p>none</p>
                )}
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </>
  )
}
