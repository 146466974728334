import React, { useContext } from "react"
import DashboardProjectAttendanceBoxTitle from "../components/DashboardProjectAttendanceBoxTitle"
import PageContext from "../context/PageContext"

export default props => {

  let nullValue={practitioners: [
    {
        "orgKey": "",
        "clientRequestRef": "",
        "forename": "",
        "surname": "",
        "email": "",
        "id": ""
    }]}


  const practitioners = (props.data?? nullValue).practitioners

  var pageContext = useContext(PageContext)
  pageContext.setPageName(`Administer Practitioners`)

  return (
    <>
      <div className="col-lg-12 mb-4">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <DashboardProjectAttendanceBoxTitle
              caption={`Practitioners (${practitioners.length})`}
            />
          </div>
          <div className="card-body">
            {practitioners.length > 0 ? (
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Forename</th>
                    <th>Surname</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {practitioners.map((elem, index) => {
                    return (
                      <tr key={index}>
                        <td>{elem.forename} </td>
                        <td>{elem.surname} </td>
                        <td>{elem.email} </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : (
              <p>none</p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
