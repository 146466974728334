import React, { useContext } from "react"
import { Link } from "gatsby"
import FontAwesomeIcon from "../components/FontAwesomeIcon"
import UserContext from "../context/UserContext"
const AdministerProjectSessionsDetail = ({
  index,
  id,
  name,
  locationName: locationId,
  start,
  end,
  leadPractitionerName: leadPractitionerId,
  session
}) => {

  const userWrapper = useContext(UserContext)
  const formatDate=(date)=>{
    const options = { dateStyle : 'short', timeStyle : 'short' };
    return `${date.toLocaleTimeString('en-GB', options)}`
  }

  const getLocationName=(id)=>{
    return userWrapper.ReferenceData.locations.find(l=>l.id===id).name
  }

  const getPractitionerName=(id)=>{
    let pract = userWrapper.ReferenceData.practitioners.find(l=>l.id===id);
    return `${pract.forename} ${pract.surname}`
  }

  return (
    <>
      <tr>
        <td>{name}</td>
        <td>{getLocationName(locationId)}</td>
        <td>{formatDate(new Date(start))}</td>
        <td>{formatDate(new Date(end))}</td>
        <td>{getPractitionerName(leadPractitionerId)}</td>
        <td>
          <Link to={`/app/administer-session/${id}`} sss="ww" name={name} session={session}>
            <FontAwesomeIcon icon="pencil-alt" />
          </Link>
        </td>
      </tr>
    </>
  )
}
export default AdministerProjectSessionsDetail
