import React from "react"

const SessionalRecordsToCompleteTitleLine = (props) => {
  return <>
    <h4 className="small font-weight-bold">{props.session.name}

      {props.caption}
      <span className="float-right"><i className={`fas fa-calendar`}
        style={{ paddingRight: "2px" }}></i>{}{props.session.date}</span>
    </h4>
  </>
}
export default SessionalRecordsToCompleteTitleLine
