import React from "react"
import DashboardProjectAttendanceBoxTitle from "../components/DashboardProjectAttendanceBoxTitle"
const SessionsScheduledForWeek = (props) => {
  return <>
    <div className="card-header py-3">
      <DashboardProjectAttendanceBoxTitle icon={props.icon} caption="Sessions Scheduled For Week"
        weekStarting={props.weekStarting} /></div>

  </>
}

export default SessionsScheduledForWeek 