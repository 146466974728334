import React from "react"
import PageContext from "../context/PageContext"
import UserContext from "../context/UserContext"
import UserHomePractitioner from "../components/UserHomePractitioner"
import UserHomeAdministrator from "../components/UserHomeAdministrator"
import Throbber from "../components/Throbber"

const tidy=(user)=>{
  return user
}

const UserHome = () => {
  return (
    <>
      <PageContext.Consumer>
        {page => (
          <UserContext.Consumer>
            {user =>
              tidy(user).isLoggedIn() === true && user.user && user.user.Permissions ? (
                user.user.Permissions.canSeeAdminDashboard ? (
                  <UserHomeAdministrator />
                ) : (
                  <UserHomePractitioner />
                )
              ) : (
                <><p>Please wait ...</p>
                <Throbber loading={true}/>
                </>
              )
            }
          </UserContext.Consumer>
        )}
      </PageContext.Consumer>
    </>
  )
}
export default UserHome
