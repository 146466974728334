import React, { useContext } from "react"
import PageContext from "../context/PageContext"
import UserHomePractitionerContent from "./UserHomePractitionerContent"

const UserHomePractitioner = () => {
  let pageContext = useContext(PageContext)
  pageContext.setPageName("Inbox")

  return (
    <>
      <PageContext.Consumer>
        {page => (
          <UserHomePractitionerContent/>
        )}
      </PageContext.Consumer>
    </>
  )
}
export default UserHomePractitioner
