import axios from "axios"
import LogService from "../services/LogService"

class AxiosService {

  url=(methodName)=>{
    return `https://GoResFuncs20200204091555.azurewebsites.net/api/${methodName}`;
    //return `http://localhost:7071/api/${methodName}`;
  }

  parseJwt(jwt, successCallback, failureCallback) {
    return this.get("ParseJwt", jwt, successCallback, failureCallback)
  }

  getReferenceData(jwt, successCallback, failureCallback) {
    return this.get("GetReferenceData", jwt, successCallback, failureCallback)
  }

  getClients(jwt, successCallback, failureCallback) {
    return this.get("Clients", jwt, successCallback, failureCallback)
  }

  getClient(jwt, id, successCallback, failureCallback) {
    return this.get(`Clients/${id}`, jwt, successCallback, failureCallback)
  }

  getAllSessionsForTenant(jwt, successCallback, failureCallback) {
    return this.get("Sessions", jwt, successCallback, failureCallback)
  }

  getSession(jwt, id, successCallback, failureCallback) {
    return this.get(`Sessions/${id}`, jwt, successCallback, failureCallback)
  }

  updateProject(jwt, body, successCallback, failureCallback) {
    return this.put(this.url("Projects"), jwt, body).then(
      result => successCallback(result),
      failureCallback
    )
  }

  updateClient(jwt, body, successCallback, failureCallback) {
    return this.put(this.url("Clients"), jwt, body).then(
      result => successCallback(result),
      failureCallback
    )
  }
  updateSession(jwt, body, successCallback, failureCallback) {
    return this.put(this.url("Sessions"), jwt, body).then(
      result => successCallback(result),
      failureCallback
    )
  }
  createSession(jwt, body, successCallback, failureCallback) {
    return this.post(this.url("Sessions"), jwt, body).then(
      result => successCallback(result),
      failureCallback
    )
  }
  createClient(jwt, body, successCallback, failureCallback) {
    return this.post(this.url("Clients"), jwt, body).then(
      result => successCallback(result),
      failureCallback
    )
  }
  get(pageName, jwt, successCallback, failureCallback) {
    return this.getData(this.url(pageName), jwt).then(
      result => successCallback(result),
      failureCallback
    )
  }
  getData(url, jwt) {
    LogService.log(`AXIOS GET ${url}`)
    var config = {
      headers: { Authorization: "Bearer " + jwt },
    }

    return axios.get(url, config)
  }
  
  post =(url, jwt, body)=> {
    LogService.log(`AXIOS POST ${url}`)
    var config = {
      headers: { Authorization: "Bearer " + jwt },
    }
    return axios.post(url, body, config)
  }

  put =(url, jwt, body)=> {
    LogService.log(`AXIOS PUT ${url}`)
    var config = {
      headers: { Authorization: "Bearer " + jwt },
    }
    return axios.put(url, body, config)
  }
}
export default AxiosService
