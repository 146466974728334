import React from "react"
import SessionalRecordsToCompleteLine from "../components/SessionalRecordsToCompleteLine"
import DashboardProjectAttendanceBoxTitle from "../components/DashboardProjectAttendanceBoxTitle"
export default (props) => {
  const attendee1={name:"Aziz Antelope"}
  const attendee2={name:"Brian Bear"}
  const session1 ={name:"Percussive Jam", date:"11-Jul-2019", attendees :[attendee1, attendee2]}
  const session2 ={name:"Drumming Jam", date:"18-Jul-2019", attendees :[attendee1]}
  return <>
  
    <div className="col-lg-6 mb-4">
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <DashboardProjectAttendanceBoxTitle caption="Sessional Records To Complete" count="3"/>
        </div>
        <div className="card-body">
        <SessionalRecordsToCompleteLine icon="plus-square" session={session1}/>
          
        <SessionalRecordsToCompleteLine icon="plus-square" session={session2}/>
          

        </div>
      </div>

    </div>
  </>
}

