import React from "react"
import DashboardProjectAttendanceBoxTitle from "../components/DashboardProjectAttendanceBoxTitle"

const FullWidthContainer = ({ children, icon, caption }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 mb-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <DashboardProjectAttendanceBoxTitle
                icon={icon}
                caption={`${caption}`}
              />
            </div>

            <div className="card-body">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default FullWidthContainer
