import React, {useContext} from "react"
import PageContext from "../context/PageContext"
import Login from "../components/login"
const LoginWrapper = () => {
  var pageContext = useContext(PageContext)
  pageContext.setPageName("Sign In")
return (
  <>
    <PageContext.Consumer>
      {page => (
      <Login/>
      )
      }
    </PageContext.Consumer>{" "}
  </>
)
}
export default LoginWrapper

