import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import AddEntity from "../components/icons/AddEntity"
import AxiosService from "../services/AxiosService"
import LogService from "../services/LogService"
import FullWidthContainer from "../components/FullWidthContainer"
import AdministerProjectSessionsDetail from "../components/AdministerProjectSessionsDetail"

const AdministerProjectSessions = ({ token, id }) => {
  const [sessions, setSessions] = useState([])


  useEffect(() => {
    // Should not ever set state during rendering, so do this in useEffect instead.
    const getSessions = () => {
      new AxiosService().getAllSessionsForTenant(token, success, fail)
    }
    const success = data => {
      let filteredSessions = data.data.data.filter(s => s.projectId === id)
      setSessions(filteredSessions)
    }
    const fail = err => {
      LogService.logHttpResponse(err)
    }
    getSessions()
  }, [id, token])
const navigateToCreate=(event)=>{
  event.preventDefault()
  navigate(`/app/administer-session/create/${id}`)}

  return (
    <>
      <FullWidthContainer caption={`Sessions: (${sessions.length}):`}>
        <div style={{ float: "right", marginTop: "-18px", marginRight: "0px" }}>
          {/* <Refresh  tooltiptext="Refresh"
  onClick={event => {
    getClients(thisUserContext.Token)
  }}
/> */}

          <div style={{ float: "left", marginTop: "0px", marginRight: "10px" }}>
            <AddEntity
              tooltiptext="Create Session"
              onClick={event => {navigateToCreate(event)}}
            />
          </div>
        </div>
        <table
          style={{
            width: "100%",
            border: "1px solid gray",
            marginBottom: "16px",
          }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Location</th>
              <th>Start</th>
              <th>End</th>
              <th>Lead Practitioner</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map((elem, index) => {
              return (
                <AdministerProjectSessionsDetail
                  key={index}
                  name={elem.name}
                  id={elem.id}
                  locationName={elem.locationId}
                  start={elem.start}
                  end={elem.end}
                  leadPractitionerName={elem.leadPractitionerId}
                  session={elem}
                />
              )
            })}
          </tbody>
        </table>
        <div style={{ float: "right", marginTop: "-18px", marginRight: "0px" }}>
          {/* <Refresh  tooltiptext="Refresh"
  onClick={event => {
    getClients(thisUserContext.Token)
  }}
/> */}

          <div style={{ float: "left", marginTop: "10px", marginRight: "10px" }}>
            <AddEntity
              tooltiptext="Create Session"
              onClick={event => {navigateToCreate(event)}}
            />
          </div>
        </div>
      </FullWidthContainer>
    </>
  )
}
export default AdministerProjectSessions
