import React from "react"
import UserContext from "../context/UserContext"

import DashboardAdministratorRow from "../components/DashboardAdministratorRow"
// import DashboardProjectBox from "../components/DashboardProjectBox"
// import DashboardProjectAttendanceBox from "../components/DashboardProjectAttendanceBox"

const GetPageContent = user => {
  let numberOfProjects =
    user.ReferenceData === null
      ? 0
      : user.ReferenceData.projects === undefined
      ? 0
      : user.ReferenceData.projects.length
  let numberOfClients =
    user.Clients === null
      ? 0
      : user.Clients === undefined
      ? 0
      : user.Clients.length

  return (
    <>
      <div className="row">
        <DashboardAdministratorRow
          projectsCount={numberOfProjects}
          clientCount={numberOfClients}
        />
      </div>
      {/* <div className="row">
        <DashboardProjectBox />
        <DashboardProjectAttendanceBox />
      </div> */}
    </>
  )
}

const UserHomeAdministratorContent = () => {
  return (
    <>
      <UserContext.Consumer>
        {user => GetPageContent(user)}
      </UserContext.Consumer>
    </>
  )
}
export default UserHomeAdministratorContent
