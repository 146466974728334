import React from "react"

export default (props) => {
  return (
  <div style={{paddingBottom:"40px"}}>
    <h4 className="small font-weight-bold"><i className={`fas fa-${props.icon}`} 
    style={{paddingRight:"2px"}}></i>{ }{props.caption}
    <span className="float-right">
    <i className="fas fa-chalkboard-teacher" style={{paddingRight:"2px"}}></i>
    <i className="fas fa-exclamation-triangle text-red "  
      hidden={props.practitionerName} style={{paddingRight:"2px"}}></i>
    {props.practitionerName || "NONE ASSIGNED"}</span></h4>

    <p className="small"><i className={`fas fa-calendar`} 
    style={{paddingRight:"2px"}}></i>{ }{props.sessionDate}
    <span className="float-right">
      <i className="fas fa-map-marker-alt" style={{paddingRight:"2px"}}></i>
    {props.location}</span></p>


    <p className="small"><i className={`fas fa-scroll`} 
    style={{paddingRight:"2px"}}></i>{props.placesBooked} of {props.capacity} places allocated
    {/* <span className="float-right">
      <i className="fas fa-map-marker-alt" style={{paddingRight:"2px"}}></i>
    {props.location}</span> */}
    </p>


    <div className="progress mb-4">
      <div className="progress-bar" role="progressbar" style={{ width: `${(100*props.placesBooked)/props.capacity}%` }} aria-valuenow={props.percentage} aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
  )
}

