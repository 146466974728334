import React from "react"

const DashboardProjectAttendanceBoxTitle = (props) => {
  return <>

    <h6 className="m-0 font-weight-bold text-primary"><i className={`fas fa-${props.icon}`}
      style={{ paddingRight: "2px" }}></i>{props.caption}
      <span style={{ float: "right" }}>
        <span hidden={!props.weekStarting}>
          <i className={`fas fa-caret-square-left`} style={{ paddingRight: "5px" }}></i>
          {`W/c ${props.weekStarting}`}
          <i className={`fas fa-caret-square-right`} style={{ paddingLeft: "5px" }}></i>
        </span></span>
    </h6>

  </>
}

export default DashboardProjectAttendanceBoxTitle