import React from "react"
import UserContext from "../context/UserContext"

import DashboardPractitionerRow from "../components/DashboardPractitionerRow"
import PractitionerSessionsToComplete from "../components/PractitionerSessionsToComplete"
import PractitionerSessionsScheduledInWeek from "../components/PractitionerSessionsScheduledInWeek"


const GetPageContent = () => {
  return (
    <>
      <div className="row">
        <DashboardPractitionerRow />
      </div>

      <div className="row">
        <PractitionerSessionsToComplete />
        <PractitionerSessionsScheduledInWeek />
      </div>
    </>
  )
}

const UserHomePractitionerContent = () => {

  return (
    <>

          <UserContext.Consumer>
            {user => GetPageContent()}
          </UserContext.Consumer>
    </>
  )
}
export default UserHomePractitionerContent
