import React from "react"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

const FormInput = ({
  register,
  label,
  property,
  required,
  minLength,
  maxLength,
  errorProp,
  value,
  onChange,
}) => {
  const tooLongValueWarning = (propertyName, value) => {
    return invalidDiv(
      `${propertyName} must be no more than ${value} characters long`
    )
  }
  const tooShortValueWarning = (propertyName, value) => {
    return invalidDiv(
      `${propertyName} must be at least ${value} characters long`
    )
  }
  const missingRequiredValueWarning = propertyName => {
    return invalidDiv(`${propertyName} is required`)
  }
  const invalidDiv = value => {
    return (
      <div style={{ color: "red" }}>
        <p>
          <FontAwesomeIcon icon="exclamation-triangle" /> {value}
        </p>
      </div>
    )
  }

  const showValidationForTextInput = (
    errorsProperty,
    label,
    minLength,
    maxLength
  ) => {
    return (
      <>
        {errorsProperty &&
          errorsProperty.type === "required" &&
          missingRequiredValueWarning(label)}
        {errorsProperty &&
          errorsProperty.type === "minLength" &&
          tooShortValueWarning(label, minLength)}
        {errorsProperty &&
          errorsProperty.type === "maxLength" &&
          tooLongValueWarning(label, maxLength)}
      </>
    )
  }

  return (
    <>
      <div className="form-group">
        <label htmlFor={property}>{label}</label>
        <input
          type="text"
          className="form-control"
          id={property}
          name={property}
          defaultValue={value || ""}
          onChange={onChange}
          ref={register({
            required: required,
            minLength: minLength,
            maxLength: maxLength,
          })}
        />
      </div>
      {showValidationForTextInput(errorProp, label, minLength, maxLength)}
    </>
  )
}

export default FormInput
