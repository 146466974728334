import React, { useContext } from "react"
import { Link } from "gatsby"
import DashboardProjectAttendanceBoxTitle from "./DashboardProjectAttendanceBoxTitle"
import FontAwesomeIcon from "./FontAwesomeIcon"

import PageContext from "../context/PageContext"
import UserContext from "../context/UserContext"

const AdministerClient = props => {
  const userWrapper = useContext(UserContext)
  const user = userWrapper.Clients.find(p => p.id === props.id)
  let projectsForUser = userWrapper.ReferenceData.projects.filter(
    p => p.clients.filter(c => c.id === props.id && c.selected).length > 0
  )
  var pageContext = useContext(PageContext)
  pageContext.setPageName(`Administer ${user.forename} ${user.surname}`)

  return (
    <>
      <PageContext.Consumer>
        {page => (
          // <div className="row">
          //   <ProjectSessionsForWeek />
          //   <ProjectEnrollment />
          // </div>

          <>
            <div className="row">
              <div className="col-lg-12 mb-12">
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <DashboardProjectAttendanceBoxTitle
                      icon={props.icon}
                      caption={`${user.forename} ${user.surname}`}
                    />
                  </div>
                  <div className="card-body">
                    <p>Forename:{user.forename}</p>
                    <p>Surname:{user.surname}</p>

                    <p>
                      Assigned to {projectsForUser.length} project
                      {projectsForUser.length === 1 ? "" : "s"}
                      {projectsForUser.length > 0 ? ":" : "."}
                    </p>
                    {projectsForUser.length > 0 ? (
                      <table
                        style={{
                          width: "100%",
                          border: "1px solid gray",
                          marginBottom: "16px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectsForUser.map((elem, index) => {
                            return (
                              <tr key={index}>
                                <td>N {elem.name}</td>
                                <td>
                                  <Link
                                    to={`/app/administer-project/${elem.id}`}
                                  >
                                    <FontAwesomeIcon icon="pencil-alt" />
                                  </Link>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </PageContext.Consumer>
    </>
  )
}
export default AdministerClient
