import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"

import FullWidthContainer from "../components/FullWidthContainer"
import AxiosService from "../services/AxiosService"
import LogService from "../services/LogService"
import PageContext from "../context/PageContext"
import UserContext from "../context/UserContext"
import FormInput from "./FormInput"

const AdministerClientEdit = props => {
  const [client, setClient] = useState({})
  var userContext = useContext(UserContext)
  var pageContext = useContext(PageContext)
  pageContext.setPageName(`Administer Client`)

  const { register, handleSubmit, errors } = useForm()
  const onSubmit = data => {
    var output = {
      OrgKey: userContext.user.orgKey,
      ClientRequestRef: new Date().getTime(),
      forename: data.forename,
      surname: data.surname,
      eTag: client.eTag,
      id: client.id,
    }
    var body = JSON.stringify(output)
    new AxiosService().updateClient(
      userContext.Token,
      body,
      successUpdateSession,
      failUpdateSession
    )
  }

  const successUpdateSession = () => {
    let ts = new Date().getTime()
    navigate(`/app/administer-clients/refresh/${ts}`)
  }

  const failUpdateSession = err => {
    LogService.logHttpResponse(err)
  }

  useEffect(() => {
    new AxiosService().getClient(
      userContext.Token,
      props.id,
      successGetClient,
      failGetClient
    )
  }, [userContext.Token, props.id])

  const successGetClient = data => {
    setClient(data.data)
  }
  const failGetClient = err => {
    LogService.logHttpResponse(err)
  }

  function handleChange(evt) {
    const value = evt.target.value
    setClient({
      ...client,
      [evt.target.name]: value,
    })
  }

  const forenameMinLength = 2
  const surnameMinLength = 2
  const forenameMaxLength = 40
  const surnameMaxLength = 40

  return (
    <>
      <PageContext.Consumer>
        {page => (
          <>
            <FullWidthContainer caption={`${client.forename}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                  required={true}
                  minLength={forenameMinLength}
                  maxLength={forenameMaxLength}
                  label="Forename"
                  property="forename"
                  register={register}
                  errorProp={errors.forename}
                  value={client.forename || ""}
                  onChange={handleChange}
                />

                <FormInput
                  required={true}
                  minLength={surnameMinLength}
                  maxLength={surnameMaxLength}
                  label="Surname"
                  property="surname"
                  register={register}
                  errorProp={errors.surname}
                  value={client.surname || ""}
                  onChange={handleChange}
                />

                <input type="submit" />
              </form>
            </FullWidthContainer>
          </>
        )}
      </PageContext.Consumer>
    </>
  )
}
export default AdministerClientEdit
