import React from "react"
import { navigate } from "gatsby"
import UserContext from "../context/UserContext"
import AxiosService from "../services/AxiosService"
import LogService from "../services/LogService"
import Throbber from "../components/Throbber"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

class Login extends React.Component {
  constructor(props) {
    super(props)
    console.log("login 20200206.1")
    new AxiosService().parseJwt("login 20200206.1")
    this.state = {
      username: ``,
      password: ``,
      lastLoginFailed: false,
      loading: false,
    }
  }
 

  thisUser
  token
  static userContextType = UserContext.Consumer

  isLoggedIn = () => {
    return Login.userContextType.isLoggedIn
  }
  GHoweAdmin001Token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL2dvcmVzYjJjMS5iMmNsb2dpbi5jb20vZThhOWQzZjYtODk2Zi00Mzk4LWI0MTgtYjRmOTQ2ZTVhNTkyL3YyLjAvIiwiZXhwIjoyOTk0OTIxNjk4LCJuYmYiOjE1NzQ1MTgwOTgsImF1ZCI6ImVjZTY3MTc1LThiNmEtNDVkMC05NmY2LWE4OGQ3ZTJjY2NlMiIsIm9pZCI6IkF6dXJlQURfT2JqZWN0SWRfR0hPV0UwMDMtQURNSU4wMDEiLCJzdWIiOiJBenVyZUFEX09iamVjdElkX0dIT1dFMDAzLUFETUlOMDAxIiwibmFtZSI6IkEgQWJydXp6byIsImVtYWlscyI6WyJhLmFicnV6em9AZ3JlZW5ob3dlLmdvcmVzdWx0cy5jby51ayJdLCJ0ZnAiOiJCMkNfMV9TaWdudXAyIiwibm9uY2UiOiJlOWNhOTA3ZC05OGRhLTQ1NmItOGE3ZS0wZWRlMmYyODkwOWQiLCJzY3AiOiJhY2Nlc3MiLCJhenAiOiJlY2U2NzE3NS04YjZhLTQ1ZDAtOTZmNi1hODhkN2UyY2NjZTIiLCJ2ZXIiOiIxLjAiLCJpYXQiOjE1NzQ1MTgwOTh9.CbVvXCJZ0iXJrDdpUxAljg2Kiz93baJTMt8AxUG_7sE"
  GroveAdmin001Token="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL2dvcmVzYjJjMS5iMmNsb2dpbi5jb20vZThhOWQzZjYtODk2Zi00Mzk4LWI0MTgtYjRmOTQ2ZTVhNTkyL3YyLjAvIiwiZXhwIjoyOTk0OTIxNjk4LCJuYmYiOjE1NzQ1MTgwOTgsImF1ZCI6ImVjZTY3MTc1LThiNmEtNDVkMC05NmY2LWE4OGQ3ZTJjY2NlMiIsIm9pZCI6IkF6dXJlQURfT2JqZWN0SWRfR1JPVkUwMDEtQURNSU4wMDEiLCJzdWIiOiJBenVyZUFEX09iamVjdElkX0dST1ZFMDAxLUFETUlOMDAxIiwibmFtZSI6IkEgQWJydXp6byIsImVtYWlscyI6WyJhLmFicnV6em9AZ3JvdmVoZWF0aC5nb3Jlc3VsdHMuY28udWsiXSwidGZwIjoiQjJDXzFfU2lnbnVwMiIsIm5vbmNlIjoiZTljYTkwN2QtOThkYS00NTZiLThhN2UtMGVkZTJmMjg5MDlkIiwic2NwIjoiYWNjZXNzIiwiYXpwIjoiZWNlNjcxNzUtOGI2YS00NWQwLTk2ZjYtYTg4ZDdlMmNjY2UyIiwidmVyIjoiMS4wIiwiaWF0IjoxNTc0NTE4MDk4fQ.Til1_taDIjpbs6QObwcq-ysio2sTxv3Aw_YdGT3U1HI"  
  validPassword = (username, password) => {
    let value = { valid: false, token: null }
    if (username === `ad` && password === ``) {
      value = {
        valid: true,
        token:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL2dvcmVzYjJjMS5iMmNsb2dpbi5jb20vZThhOWQzZjYtODk2Zi00Mzk4LWI0MTgtYjRmOTQ2ZTVhNTkyL3YyLjAvIiwiZXhwIjoyOTk0OTIxNjk4LCJuYmYiOjE1NzQ1MTgwOTgsImF1ZCI6ImVjZTY3MTc1LThiNmEtNDVkMC05NmY2LWE4OGQ3ZTJjY2NlMiIsIm9pZCI6IkF6dXJlQURfT2JqZWN0SWRfTlBBUkswMDEtQURNSU4wMDEiLCJzdWIiOiJBenVyZUFEX09iamVjdElkX05QQVJLMDAxLUFETUlOMDAxIiwibmFtZSI6IkEgQWdnYXJ3YWwiLCJlbWFpbHMiOlsiYS5hZ2dhcndhbEBub3J0aHBhcmsuZ29yZXN1bHRzLmNvLnVrIl0sInRmcCI6IkIyQ18xX1NpZ251cDIiLCJub25jZSI6ImU5Y2E5MDdkLTk4ZGEtNDU2Yi04YTdlLTBlZGUyZjI4OTA5ZCIsInNjcCI6ImFjY2VzcyIsImF6cCI6ImVjZTY3MTc1LThiNmEtNDVkMC05NmY2LWE4OGQ3ZTJjY2NlMiIsInZlciI6IjEuMCIsImlhdCI6MTU3NDUxODA5OH0.1-_9lD5ZVYAfZP7S4pmB5fnNQexR1AE5m23cXedh1Yo",
      }
    }
    if (username === `grad` && password === `admin`) {
      value = { valid: true, token: this.GroveAdmin001Token }
    }

    if (username === `pr` && password === ``) {
      value = {
        valid: true,
        token:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL2dvcmVzYjJjMS5iMmNsb2dpbi5jb20vZThhOWQzZjYtODk2Zi00Mzk4LWI0MTgtYjRmOTQ2ZTVhNTkyL3YyLjAvIiwiZXhwIjoyOTk0OTIxNjk4LCJuYmYiOjE1NzQ1MTgwOTgsImF1ZCI6ImVjZTY3MTc1LThiNmEtNDVkMC05NmY2LWE4OGQ3ZTJjY2NlMiIsIm9pZCI6IkF6dXJlQURfT2JqZWN0SWRfTlBBUkswMDEtUFJBQ1QwMDEiLCJzdWIiOiJBenVyZUFEX09iamVjdElkX05QQVJLMDAxLVBSQUNUMDAxIiwibmFtZSI6IlAgUHJva29maWV2IiwiZW1haWxzIjpbInAucHJva29maWV2QG5vcnRocGFyay5nb3Jlc3VsdHMuY28udWsiXSwidGZwIjoiQjJDXzFfU2lnbnVwMiIsIm5vbmNlIjoiZTljYTkwN2QtOThkYS00NTZiLThhN2UtMGVkZTJmMjg5MDlkIiwic2NwIjoiYWNjZXNzIiwiYXpwIjoiZWNlNjcxNzUtOGI2YS00NWQwLTk2ZjYtYTg4ZDdlMmNjY2UyIiwidmVyIjoiMS4wIiwiaWF0IjoxNTc0NTE4MDk4fQ.M3E50YsVuAhBmx5t5u3NLtEdovUTE-hXgBCsL1-5CmY",
      }
    }
    return value
  }

  handleLogin = ({ username, password }) => {
    let validToken = this.validPassword(username, password)
    if (validToken.valid) {
      this.setState({ loading: true })
      this.getParsedJwt(validToken.token)
    } else {
      this.setState({ lastLoginFailed: true })
      this.setState({ loading: false })
    }
    return false
  }

  success = data => {
    this.thisUser.setUser(data.data)
    this.getClients(this.token)
  }
  fail = err => {
    LogService.logHttpResponse(err)
    console.log(err)
    this.setState({ loading: false })
  }

  getParsedJwt = token => {
    this.thisUser.setToken(token)
    new AxiosService().parseJwt(token, this.success, this.fail)
    this.token = token
  }

  getClients = token => {
    new AxiosService().getClients(token, this.successClient, this.failClient)
  }
  successClient = data => {
    this.thisUser.setClients(data.data)
    this.getReferenceData(this.token)
    this.setState({ loading: false })
  }
  failClient = err => {
    LogService.logHttpResponse(err)
  }

  successRefData = data => {
    this.thisUser.setReferenceData(data.data)
  }
  failRefData = err => {
    LogService.logHttpResponse(err)
  }

  getReferenceData = token => {
    new AxiosService().getReferenceData(
      token,
      this.successRefData,
      this.failRefData
    )
  }

  logout = callback => {
    callback()
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.handleLogin(this.state)
  }

  render() {
    if (this.isLoggedIn()) {
      navigate(`/app/home`)
    }
    return (
      <div>
        <UserContext.Consumer>
          {user => (
            <>
              <div hidden={!this.state.lastLoginFailed} style={{ color: "red" }}>
                <p>
                  <FontAwesomeIcon icon="exclamation-triangle" /> Your username and password combination was not recognised. Please retry
                </p>
              </div>
              <Throbber loading={this.state.loading} />
              <h1>Sign In</h1>
              <form
                method="post"
                onSubmit={event => {
                  this.thisUser = user

                  this.handleSubmit(event)
                }}
              >
                <label>
                  Username
                  <input
                    type="text"
                    name="username"
                    onChange={this.handleUpdate}
                  />
                </label>
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  onChange={this.handleUpdate}
                />

                <input type="submit" value="Sign In" />
              </form>
              {/* <button onClick={this.autoAdminLogin(user)}>Login As Admin</button> */}
            </>
          )}
        </UserContext.Consumer>
      </div>
    )
  }
}

export { Login as default }
