import React, { useContext } from "react"
import PageContext from "../context/PageContext"

const Profile = () => {
  var pageContext = useContext(PageContext)
  pageContext.setPageName("Profile")
  return (
    <PageContext.Consumer>
      {page => (
        <>
          <h1>Your profile</h1>
          <ul>
            <li>Name: Your name will appear here</li>
            <li>E-mail: And here goes the mail</li>
          </ul>
        </>
      )}
    </PageContext.Consumer>
  )
}

export default Profile
