import React from "react"
import {Link} from "gatsby"

export default (props) => {
  return <>
    <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                <Link to={props.to}>
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{props.caption}</div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">{props.count}</div>
                    </div>
                    <div className="col-auto">
                    <i className={`fas  fa-2x text-gray-300 ${props.icon}`}></i>
                                        </div>
                  </div></Link>
                </div>
              </div>
            </div>
  </>
}

