import React from "react"
import FontAwesomeIcon from "../../components/FontAwesomeIcon"

export default props => {
  return (
    <>
      <FontAwesomeIcon
        icon="plus-square"
        scale="2x"        
        tooltiptext={props.tooltiptext}
        onClick={props.onClick}
      />
    </>
  )
}
