import React from "react"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

const FormSelect = ({
  required,
  label,
  register,
  errorProp,
  data,
  dataLabel,
  validator,
  validatorMessage,
  value,
  property,
  onChange

}) => {

  const missingRequiredValueWarning = propertyName => {

    return invalidDiv(`${propertyName} is required`)
  }
  const invalidDiv = value => {
    return (
      <div style={{ color: "red" }}>
        <p>
          <FontAwesomeIcon icon="exclamation-triangle" />
           {value}
        </p>
      </div>
    )
  }

  const showValidationForTextInput = (errorsProperty, label) => {
    return (
      <>
        {errorsProperty &&
          errorsProperty.type === "required" &&
          missingRequiredValueWarning(label)}
        {errorsProperty &&
          errorsProperty.type === "validate" &&
          invalidDiv(validatorMessage??"An unknown validation error occurred")}
      </>
    )
  }
  return (
    <>
      <div className="form-group row">
        <label htmlFor={property}  className="col-sm-2 col-form-label">{label}</label>
        <div className="col-sm-10">
        <select
          className="form-control"
          id={property}
          value={value || ''}
          name={property}
          ref={register({
            required: required,
            validate: value =>(validator!==undefined?validator(value):true)
          })}
          onChange={event=>{onChange(event)}}
        >
          <option value="">Select...</option>

          {data.map((elem, index) => {
            return (
              <option key={index} value={elem.id}>
                {dataLabel(elem)}
              </option>
            )
          })}
        </select></div>
      </div>
      {showValidationForTextInput(errorProp, label)}
    </>
  )
}

export default FormSelect
