import React from "react"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

const FormInputControlled = ({
  register,
  label,
  property,
  required,
  minLength,
  maxLength,
  errorProp,
  defaultValue,
  validate
}) => {
  const tooLongValueWarning = (propertyName, value) => {
    return invalidDiv(
      `${propertyName} must be no more than ${value} characters long`
    )
  }
  const tooShortValueWarning = (propertyName, value) => {
    return invalidDiv(
      `${propertyName} must be at least ${value} characters long`
    )
  }
  const missingRequiredValueWarning = propertyName => {
    return invalidDiv(`${propertyName} is required`)
  }
  const invalidDiv = value => {
    return (
      <div style={{ color: "red" }}>
        <p>
          <FontAwesomeIcon icon="exclamation-triangle" /> {value}
        </p>
      </div>
    )
  }

  const showValidationForTextInput = (
    errorsProperty,
    label,
    minLength,
    maxLength
  ) => {

    return (
      <>
        {errorsProperty &&
          errorsProperty.type === "required" &&
          missingRequiredValueWarning(label)}
        {errorsProperty &&
          errorsProperty.type === "minLength" &&
          tooShortValueWarning(label, minLength)}
        {errorsProperty &&
          errorsProperty.type === "maxLength" &&
          tooLongValueWarning(label, maxLength)}
      </>
    )
  }

  return (
    <>
      <div className="form-group row">
        <label htmlFor={property} className="col-sm-2 col-form-label">
          {label}
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id={property}
            name={property}
            defaultValue={defaultValue}
            ref={register({
              required: required,
              minLength: minLength,
              maxLength: maxLength,
              validate: validate
            })}
          />
        </div>
      </div>
      {showValidationForTextInput(errorProp, label, minLength, maxLength)}
    </>
  )
}

export default FormInputControlled
