import React, { useContext } from "react"
import PageContext from "../context/PageContext"
import UserHomeAdministratorContent from "./UserHomeAdministratorContent"

const UserHomeAdministrator = () => {
  let pageContext = useContext(PageContext)
  pageContext.setPageName("Dashboard")

  return (
    <>
      <PageContext.Consumer>
        {page => (
          <UserHomeAdministratorContent/>
        )}
      </PageContext.Consumer>
    </>
  )
}
export default UserHomeAdministrator
