import React   from "react"
export default props => {
  
  return (
    <>
      <tr>
        <td>{props.forename}</td>
        <td>{props.surname}</td>
        <td>
          <input type="checkbox" name={props.id} checked={props.selected} onChange={props.onChange}/>
        </td>
      </tr>
    </>
  )
}
